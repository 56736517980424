.search-overlay {
  position: fixed;
  top: 60px; /* Adjust this to move the search bar closer to the top */
  left: 0;
  right: 0;
  bottom: 0; /* Keep it full height to cover the screen */
  background: rgba(0, 0, 0, 0.7); /* Dimmed background */
  z-index: 9999;
  display: flex;
  align-items: flex-start; /* Align to the top */
  justify-content: center; /* Center horizontally */
  padding-top: 20px; /* Padding at the top to add space between the top and search bar */
}

.search-bar {
  position: relative;
  width: 90%;
  max-width: 500px;
  background: rgba(255, 255, 255, 0.95); /* Slightly more opaque for a richer look */
  border-radius: 30px; /* Smooth, rounded corners */
  padding: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15); /* Softer shadow */
  border: 2px solid #ddd; /* Thicker border for more emphasis */
}

.search-bar input {
  width: 100%;
  padding: 12px 15px;
  border: 2px solid #ddd; /* Matching the search bar border */
  border-radius: 30px; /* Keep the corners consistent */
  background: #fff;
  font-size: 16px;
  outline: none;
  box-shadow: none; /* No shadow for the input itself */
}
.close-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  font-size: 20px;
  cursor: pointer;
  color: #666;
}


.browseComponents .breadCrumbs {
  display: flex;
  align-items: center;
}

.breadCrumbs a {
  margin-right: 5px;
}

.filter-icon {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.browseComponents {
  display: flex;
  flex-direction: column;
  color: #000;
  margin-top: 7rem;
  &__title {
    align-self: center;
    padding: 4rem 0;
    font-size: 2.8rem;
    font-weight: 600;
  }
  &__container {
    display: flex;
    gap: 2rem;
    padding: 3rem;
    border-radius: 1rem;
  }
  &__filter {
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem;
    background: white;
    width: 30rem;
    max-width: 30rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 1rem;
    &-title {
      font-size: 2rem;
      font-weight: 500;
      justify-content: space-between;
      display: flex;
      align-items: center;
      padding-right: 1rem;
      margin-bottom: 4rem;
    }
    svg {
      display: none;
      cursor: pointer;
    }

    form {
      display: flex;
      flex-direction: column;
      margin-bottom: 3rem;
      gap: 1.6rem;
      label {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        font-size: 1.6rem;
        font-weight: 500;
        select {
          outline: none;
          border: none;
          border-radius: 5px;
          border: 1px solid #e8e8e8;
          padding: 0.8rem;
          height: 5.5rem;
          font-size: 1.6rem;
          font-weight: 400;
          appearance: none;
          background-repeat: no-repeat;
          background-image: url("../assets/img/arrow.svg");
          background-position: calc(100% - 21px) 50%;
        }
        input {
          outline: none;
          border: none;
          border-radius: 5px;
          border: 1px solid #e8e8e8;
          padding: 0.8rem;
          height: 5.5rem;
          font-size: 1.6rem;
          font-weight: 400;
          appearance: none;
        }
      }
    }

    &-btn {
      display: inline-block;
      padding: 1rem 2rem; /* Increased padding for better spacing */
      font-size: 1.6rem; /* Increased font size */
      font-weight: 600; /* Bold font weight */
      border-radius: 8px; /* Rounded corners */
      transition: all 0.3s ease-in-out; /* Smooth transition for hover effects */
      cursor: pointer;
      border: 2px solid transparent;
        cursor: pointer;
    }
  }
  &__content {
    display: flex;
    width: 100%;
    background: #fff;
    padding: 3rem 0rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    flex-direction: column;
    border-radius: 1rem;
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 2rem;
      padding-bottom: 1rem;
      border-bottom: 2px solid #000;
      gap: 1.5rem;
      span {
        font-size: 1.5rem;
        cursor: pointer;
        font-weight: 600;
        flex: 1;
        &:nth-of-type(1) {
          flex: 2;
        }
      }
    }
    .pagination {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
    &-container {
      display: flex;
      flex-direction: column;
      overflow-x: auto; // Allow horizontal scrolling if needed
      width: 100%;
      // gap: 3rem;
      // animation: 1s appear;
    }
    &-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1.5rem;
      padding: 3rem 2rem;
      cursor: pointer;

      &.odd {
        background-color: #eee;
      }
      span {
        font-size: 1.3rem;
        font-weight: 400;
        flex: 1;
        &:nth-of-type(1) {
          flex: 2;
        }
      }
    }
  }
  &__product-container {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 2rem;
    gap: 1rem;
    border-bottom: 2px solid rgba(40, 40, 40);
    color: black;
    section {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      span {
        font-size: 1.6rem;
        font-weight: 600;
        &:nth-of-type(2) {
          font-size: 1.8rem;
        }

        a {
          padding: 0.8rem 1.2rem;
          color: white;
          background: black;
          cursor: pointer;
          text-decoration: none;
          border-radius: 0.6rem;
          font-size: 1.3rem;
        }
      }
    }
    div {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      p {
        flex: 1;
        font-size: 1.5rem;
        font-weight: 500;
        color: rgb(112, 112, 112);
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        span {
          color: rgb(0, 0, 0);
        }
      }
    }
  }

  &__header {
    &-container {
      display: flex;
      background-color: rgba(40, 40, 40);
      align-items: center;
      z-index: 9999;
      width: 100%;
      position: fixed;
      .header {
        position: relative;
      }
    }
    &-hamburger {
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 5px;
      transition: all 0.3s ease-in-out;
      span {
        width: 25px;
        height: 2.5px;
        background-color: white;
        transition: all 0.3s ease-in-out;
      }
      &.active {
        gap: 0px;
        span {
          &:nth-of-type(2) {
            opacity: 0;
          }
          &:first-of-type {
            transform: rotate(-45deg);
            margin-bottom: -2.5px;
          }
          &:last-of-type {
            margin-top: -2.5px;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}

.sidebar {
  &__filters {
    &-container {
      position: fixed;
      top: 0%;
      left: 0%;
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.605);
      z-index: 8888;
      display: none;
      &.active {
        display: block;
      }
    }
    &-title {
      font-size: 2.4rem;
      font-weight: 600;
      line-height: 3.2rem;
      color: black;
    }
    padding: 5rem 1.5rem;
    padding-top: 12rem;
    background-color: white;
    width: 80%;
    height: 100%;
    overflow-y: scroll;
  }
}

.pagination {
  text-align: center;
  align-self: center;
  transform: scale(1.5);
  --bs-pagination-active-bg: #000 !important;
  --bs-pagination-active-border-color: #000 !important;
  --bs-pagination-color: #000 !important;
  --bs-pagination-hover-color: #000 !important;
}

@keyframes appear {
  0% {
    display: none;
    transform: scale(0);
  }
  99% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    display: flex;
  }
}



@media only screen and (max-width: 1000px) {
  .browseComponents {
    &__container {
      flex-direction: column;
    }
    &__filter {
      flex-direction: column;
      width: 100%;
      max-width: none;
      &-title {
        margin-bottom: 0;
        svg {
          display: block;
          transition: all 0.3s ease-in-out;
          cursor: pointer;
          &.active {
            transform: rotate(180deg);
          }
        }
      }
      form {
        margin-top: 3rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 3rem;
        justify-content: center;
        gap: 1.6rem;
        label {
          width: 100%;
          max-width: 35rem;
          min-width: 15rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .browseComponents {
    &__content {
      overflow-y: hidden;
      overflow-x: scroll;
      width: 100%;
      &-title {
        width: 80rem;
      }
      &-box {
        width: 80rem;
      }
    }
    &__product-container {
      section {
        span {
          a {
            padding: 0.8rem 1.2rem;
            color: white;
            background: green;
            cursor: pointer;
            text-decoration: none;
            border-radius: 0.6rem;
            font-size: 1.3rem;
          }
        }
      }
    }
  }
  .pagination {
    margin-top: 3rem;
  }
}

@media only screen and (max-width: 800px) {
  .browseComponents {
    &__content {
      &-title {
        padding: 0 1rem;
        padding-bottom: 1rem;
        gap: 1rem;
        span {
          font-size: 1.3rem;
        }
      }
      &-box {
        gap: 1rem;
        padding: 1.5rem 1rem;
        cursor: pointer;
        span {
          font-size: 1.1rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .browseComponents {
    &__content {
      &-title {
        padding: 0 1rem;
        padding-bottom: 1rem;
        gap: 1rem;

        span {
          font-size: 1.3rem;
        }
      }

      &-box {
        gap: 1rem;
        padding: 1.5rem 1rem;
        cursor: pointer;

        span {
          font-size: 1.1rem;
        }
      }
    }

    &__filter {
      background: none;
      box-shadow: none;
      padding: 0;

      form label {
        color: #000;
        max-width: 100%;
      }
    }
  }

  .mobile-view-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 25px;
    padding: 0 1rem;  // Ensure there is no padding causing issues

    .ant-pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
    }

    .ant-pagination-total-text {
      display: block;
      width: 100%;
      margin-bottom: 1rem;
      font-size: 1.2rem;
      color: #000;
      text-align: center;
    }

    .ant-pagination-item {
      margin: 0 5px;
    }

    .ant-pagination-item-active {
      border-color: #007bff;
    }

    .ant-pagination-item a {
      color: #007bff;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}



@media only screen and (max-width: 740px) {
  .browseComponents {
    form {
      flex-wrap: nowrap;
      flex-direction: column;
      label {
        max-width: none;
        min-width: none;
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  body .sidebar {
    &__filters {
      width: 100% !important;
    }
  }
}
.listings-count {
  padding: 5px 10px;
  margin-left: auto;
  margin-right: 2rem;
  background-color: #f2f2f2;
  color: #333;
  font-weight: bold;
  border-radius: 2px;
  cursor: pointer;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 50px;
}

.listings-count:hover {
  background-color: #e6e6e6;
}

.listings-count.disabled {
  background-color: #cccccc;
  cursor: default;
  color: #666;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 12px;
}

.card {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  // overflow: hidden;
  width: 300px; /* adjust as needed */
  cursor: pointer;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.card-header,
.card-body,
.card-footer {
  padding: 16px;
}

.card-header {
  background-color: #f1f1f1;
  border-bottom: 1px solid #e1e1e1;
}

.product-name {
  margin: 0;
  color: #333;
  font-size: 1.2em;
}

.product-brand,
.product-detail,
.product-price,
.product-price-per-round,
.product-lowest-price {
  display: block;
  margin: 8px 0;
  color: #666;
  font-size: 0.9em;
}

.product-price,
.product-price-per-round,
.product-lowest-price {
  color: #d00;
  font-weight: bold;
}

.card-footer {
  background-color: #f1f1f1;
  border-top: 1px solid #e1e1e1;
  text-align: center;
}

.product-listings-link {
  display: inline-block;
  background-color: #333;
  color: #fff;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 0.9em;
}

.filter-icon{
    display: none;
    cursor: pointer;
    transform: scale(1.01);
}

.filter-icon:hover{
    transform: scale(1.1);
}

.mobile-view-pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
}

@media only screen and (max-width: 700px) {
    .filter-icon{
        display: block;
    }
  }
  .button-container {
    display: flex;
    gap: 10px; /* Space between buttons */
    justify-content: center; /* Center align the buttons */
    margin-top: 20px; /* Margin to separate from the form */
  }
  
  .browseComponents__filter-btn.apply {
    background-color: #007bff; /* Primary color for Apply */
    color: white;
    border: 2px solid #007bff;
  }
  
  .browseComponents__filter-btn.apply:hover {
    background-color: transparent;
    color: #007bff;
  }
  
  .browseComponents__filter-btn.reset {
    background-color: #6c757d; /* Neutral color for Reset */
    color: white;
    border: 2px solid #6c757d;
  }
  
  .browseComponents__filter-btn.reset:hover {
    background-color: transparent;
    color: #6c757d;
  }

  .table-container {
    width: 100%; // Ensure the container takes full width
    overflow-x: auto; // Allow horizontal scrolling if needed
  }
  
  .table-container table {
    width: 100%; // Ensure the table takes full width of the container
    border-collapse: collapse;
    table-layout: auto;
  }
  
  .table-container th, .table-container td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }