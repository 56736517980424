.about {
  padding: 10rem 0;
  width: 80%;
  margin: auto;
  font-size: 2.7rem;
  color: black;
  font-weight: 600;

  p {
    font-size: 1.6rem;
    color: #27333c;
    font-weight: 400;
    line-height: 1.6;
  }

  h3 {
    font-size: 1.8rem;
    font-weight: 600;
    margin-block: 1.8rem 1rem;
  }

  b {
    font-weight: 600;
  }
}

