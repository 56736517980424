.modal {
    padding: 2.4rem 3.6rem !important;
    &__title {
      font-size: 3.2rem;
      font-weight: 600;
    }
    form {
      display: flex;
      margin-top: 2.4rem;
      flex-direction: column;
      gap: 1.6rem;
      .input {
        width: 450px;
        padding: 8px 16px;
        outline: none;
      }
      .button {
        margin-left: auto;
        background: none;
        cursor: pointer;
        padding: 5px 10px;
        border: 1px solid #ddd;
        color: #ddd;
        font-family: "Montserrat", sans-serif;
        transition: all 0.2s ease-out;
        font-size: 1.8rem;
        &:hover {
          border: 1px solid #eee;
          color: #eee;
          border-radius: 5px;
        }
      }
    }
    p {
      font-size: 1.5rem;
      font-weight: 400;
      cursor: pointer;
      text-decoration: underline;
    }
    .error {
      font-size: 1.3rem;
      font-weight: 400;
      color: red;
      width: 450px;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .modal {
      width: calc(100% - 72px);
      form {
        .input {
          width: 100%;
        }
      }
      .error {
        width: 100%;
      }
    }
  }
  