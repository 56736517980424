.footer {
  background-color: rgb(40, 40, 40);
  padding: 2rem 5rem;
  color: white;

  &__content {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding-bottom: 2rem;
    border-bottom: 1px solid #333;
  }

  &__section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h4 {
      margin-bottom: 1rem;
      font-size: 1.8rem;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 0.5rem;

        a, button, p {
          color: white;
          text-decoration: none;
          font-size: 1.4rem;
          background: none;
          border: none;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__section--logo {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 150px;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.4rem;
    }
  }

  &__bottom-text {
    text-align: center;
    padding-top: 1rem;
    font-size: 1.4rem;
  }
  .footer__login {
    color: white;
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .logout {
    color: red;
  }
}

.modal {
  padding: 2.4rem 3.6rem !important;
  &__title {
    font-size: 3.2rem;
    font-weight: 600;
  }
  form {
    display: flex;
    margin-top: 2.4rem;
    flex-direction: column;
    gap: 1.6rem;
    input {
      width: 450px;
      padding: 8px 16px;
      outline: none;
    }
    button {
      margin-left: auto;
      background: none;
      cursor: pointer;
      padding: 5px 10px;
      border: 1px solid #ddd;
      color: #ddd;
      font-family: "Montserrat", sans-serif;
      transition: all 0.2s ease-out;
      font-size: 1.8rem;
      &:hover {
        border: 1px solid #eee;
        color: #eee;
        border-radius: 5px;
      }
    }
  }
  p {
    font-size: 1.5rem;
    font-weight: 400;
    cursor: pointer;
    text-decoration: underline;
  }
  .error {
    font-size: 1.3rem;
    font-weight: 400;
    color: red;
    width: 450px;
  }
}

@media only screen and (max-width: 600px) {
  .modal {
    width: calc(100% - 72px);
    form {
      input {
        width: 100%;
      }
    }
    .error {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 900px) {
  .footer__content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer__section {
    align-items: center;
    margin-bottom: 2rem;

    h4 {
      text-align: center;
    }
  }
}

.footerLogo {
  font-size: 2rem;
  color: white;
  text-decoration: none;
  cursor: pointer;
  img {
    width: 200px;
  }
}
