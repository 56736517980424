.product {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
  margin-top: 8rem;

  &__box {
    display: flex;
    width: 100%;
    padding-bottom: 6rem;
    gap: 3rem;
  }
  &__brand-header {
    text-align: left;
    padding: 1rem 0; // Add padding to match design needs
    margin-top: 2rem; // Add spacing below breadcrumbs
    margin-bottom: 2rem; // Add spacing above the rest of the content

    &__brand-name {
      font-size: 2rem; /* Adjust font-size as needed */
      color: #333; /* Choose a color that fits the design */
      margin-bottom: 0.5rem; /* Add some space between the brand and product name */
      text-transform: uppercase; /* Optional: to make the brand name stand out */
      font-weight: bold;
      font-size: 2rem; /* Adjust font-size as needed */
      color: #333; /* Choose a color that fits the design */
      margin-bottom: 0.5rem; /* Add some space between the brand and product name */
      text-transform: uppercase; /* Optional: to make the brand name stand out */
      font-weight: bold;
    }

    &__product-name {
      font-size: 2rem; /* Match the product name font size to the brand name */
      color: #555;
      font-weight: normal;
      margin-bottom: 1rem;
    }
  }

  &__title {
    text-align: center;
    padding: 6rem 7.2rem;

    background-color: #000;
    font-size: 2.6rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;

    span {
      font-size: 2.4rem;
      font-weight: 500;
    }
  }

  &__table {
    display: flex;
    flex-direction: column;

    padding: 3rem 2rem;
    background: white;
    width: 30rem;
    max-width: 30rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 1rem;
    gap: 3.6rem;

    &-title {
      font-size: 2rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      padding-right: 1rem;
      // margin-bottom: 4rem;
      color: #000;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 16px;
      list-style: none;

      li {
        display: flex;
        gap: 4px;
        font-size: 1.4rem;
        font-weight: 600;
        color: #000;
        flex-direction: column;

        span {
          font-size: 1.4rem;
          font-weight: 500;
          color: rgb(40, 40, 40);
        }
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    display: flex;
    background: #fff;
    padding: 3rem 0rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    flex-direction: column;
    border-radius: 1rem;

    &-title {
      font-size: 2.2rem;
      font-weight: 500;
      padding-bottom: 0.8rem;
      padding-left: 1.6rem;
      color: #000;
      border-bottom: 1px solid #000;
    }
  }

  &__content {
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1.6rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid rgb(194, 194, 194);
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      gap: 0;

      span {
        font-size: 1.2rem;
        font-weight: 400;
        color: black;
        flex: 1;

        &.promo {
          flex: 2;
        }
      }
    }

    &-container {
      display: flex;
      flex-direction: column;
      // gap: 3rem;
    }

    &-box {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      gap: 1.5rem;
      padding: 1.5rem 1.6rem;
      cursor: pointer;

      &.odd {
        background-color: #eee;
      }

      span {
        font-size: 1.3rem;
        font-weight: 500;
        flex: 1;
        color: #000;

        &.promo {
          flex: 1;
        }

        a {
          padding: 0.8rem 1.2rem;
          color: white;
          background: black;
          cursor: pointer;
          text-decoration: none;
          border-radius: 0.6rem;
          font-size: 1.3rem;
        }
      }
    }
  }

  &__search {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-top: 2.4rem;

    button {
      outline: none;
      border: none;
      padding: 16px 24px;
      font-size: 1.8rem;
      font-weight: 500;
      color: white;
      background-color: rgba(40, 40, 40);
      border-radius: 8px;
    }
  }
}
.set-global-alert-btn {
  padding: 0.8rem 1.2rem;
  color: white;
  background: black;
  cursor: pointer;
  text-decoration: none;
  border-radius: 0.6rem;
  font-size: 1.3rem;
  border: 1px solid transparent;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: darken(black, 5%);
  }
}


@media only screen and (max-width: 1200px) {
  .product {
    &__box {
      flex-direction: column;
    }

    &__table {
      width: 100%;
      max-width: none;

      ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .sidebar__filters {
    width: 80% !important;
  }
}

@media only screen and (max-width: 1050px) {
  .product {
    &__content {
      &-title {
        gap: 1rem;
        padding-right: 1rem;
        padding-left: 1rem;

        span {
          font-size: 1.2rem;
        }
      }

      &-container {
        display: flex;
        flex-direction: column;
        // gap: 3rem;
      }

      &-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        padding: 1.5rem 1rem;
        cursor: pointer;

        &.odd {
          background-color: #eee;
        }

        span {
          font-size: 1.2rem;

          a {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .product {
    &__title {
      font-size: 2.4rem;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.6rem;

      span {
        font-size: 2.2rem;
        font-weight: 500;
      }
    }

    &__search {
      flex-direction: column;

    }
  }
}

@media only screen and (max-width: 700px) {
  .product {
    &__container {
      overflow: auto;

      &-title {
        width: 100%;
      }
    }

    &__content {
      &-title {
        width: 80rem;
      }

      &-container {
        width: 80rem;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .product {
    &__table {
      ul {
        grid-template-columns: 1fr;
      }
    }
  }
}

.modal__box {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-direction: column;

  label {
    width: max-content;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1.5rem;

    input[type="checkbox"] {
      width: 18px;
      height: 18px;
    }
  }

  input[type="number"] {
    width: 365px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .modal__box input[type="number"] {
    width: 100%;
  }
}


.sortable-header {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sort-icon {
  margin-left: 8px; /* Adjust as needed */
  font-size: 0.8em; /* Adjust as needed */
}

.product__container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
.product__container-filters {
  // Styles for desktop filters
}
.product__container-filters-mobile {
  display: flex;
  gap: 10px;
  // Add more styles for mobile filters
}
.filter-button {
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #F8F8F8;
  border-radius: 5px;
  cursor: pointer;
  &.active {
    background-color: #E0E0E0;
    font-weight: bold;
  }
}

////////////////////////////////////////////////////////////////
/// 
.vendor-info {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr)); /* Creates a five-column layout with flexible sizes */
  // gap: 0.1rem; /* Space between grid items */
  align-items: center;
  text-align: left;
}
/* Make sure that the titles have the same grid layout */
.product__content-title {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr)); /* Adjust to match the number of headers you have */
  // gap: 0.5rem; /* Space between the header items */
}

.product__content-title, .vendor-info {
  display: flex;
  // grid-template-columns: 2fr 1fr 1fr 1fr 1fr; /* Adjust the fractions based on your content */
  gap: 0.5rem;
}
.sortable-header {
  display: flex;
  align-items: center;
}
