.registerEmail {
    h1 {
        color: black;
        font-size: 5.5rem;
        padding: 3rem 5rem;
        font-weight: 700;
        margin-top: 8rem;
        text-align: center;
    }
}
