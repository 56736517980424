.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%; 
    padding: 0;
    margin: 0;
  }
  
  .nav {
    background-color: #fff;
    padding: 1rem;
    width: 100%;
  
    ul {
      list-style: none;
      display: flex;
      justify-content: space-around;
      margin: 0;
      padding: 0;
  
      li {
        a {
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
  
  .main {
    flex: 1;
    // padding: 1rem;
    width: 100%; 
  }
  