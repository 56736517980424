.blogContainer {
    .main {
        max-width: 80%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        p.paragraph {
            font-size: 18px;
            font-weight: 500;
            align-self: center;
            color: #27333c;
        }
    }
    .main-header {
        background-color: #27333c;
        height: 40rem;
        max-width: 100vw;
        margin-bottom: 3.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .header-text {
        font-weight: 700;
        font-size: 4.2rem;
        color: #fff;
        margin: 1.6rem;
    }

    .secondary-heading {
        padding: 3.2rem 0 1.8rem 0;
    }
    .secondary-text {
        font-weight: 600;
        font-size: 2.4rem;
        color: #27333c;
    }

    .grid {
        display: grid;
        padding: 3.2rem 0;
        gap: 2.4rem;
        row-gap: 3.2rem;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }

    a {
        height: 100%;
        display: inline-block;
        width: 100%;
    }

    a:link,
    a:visited {
        text-decoration: none;
        color: #27333c;
    }
    a:hover,
    a:active {
        text-decoration: none;
        color: #27333c;
    }

    .card {
        max-width: 350px;
        background-color: #fff;
        color: #27333c;
        /* padding: 1.6rem 1.8rem; */
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 2.4rem;
        overflow: hidden;
        /* width: 80%; */
        height: 100%;
        width: 100%;
    }
    .card:hover,
    .card:active {
        transition: all 0.4s;
        transform: translateY(-1.3rem);
        cursor: pointer;
    }
    .card-content {
        display: flex;
        flex-direction: column;
        padding: 2.4rem 3.2rem;
        gap: 1.6rem;
    }
    .image {
        width: 100%;
        height: auto;

        /* border-radius: 2.4rem; */
    }
    .card-heading {
        font-size: 2.2rem;
        font-weight: 600;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    .card-description {
        font-size: 1.4rem;
        line-height: 135%;
        word-spacing: 0.5px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }
}

@media only screen and (max-width: 1150px) {
    .blogContainer {
        .card {
            max-width: 100%;
        }
    }
}

@media only screen and (max-width: 930px) {
    .blogContainer {
        .main {
            max-width: 90%;
        }
        .secondary-heading {
            padding: 1.2rem 0 1.8rem 0;
        }
    }
}

@media only screen and (max-width: 768px) {
    .blogContainer {
        .header-text {
            font-size: 3.2rem;
        }
        .main-header {
            height: 32rem;
        }
        .card-content {
            display: flex;
            flex-direction: column;
            padding: 2rem 2.4rem;
            gap: 1.2rem;
        }
    }
}

@media only screen and (max-width: 700px) {
    .blogContainer {
        .grid {
            grid-template-columns: 1fr;
        }
        .card {
            display: flex;
            flex-direction: row-reverse;
            width: 100%;
            gap: 2rem;
            box-shadow: none;
            border-radius: 0px;
            padding: 1.8rem 2rem;
            align-items: center;
        }
        .card img {
            width: 250px;
            height: auto;
            border-radius: 6px;
        }
        .card .card-content {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            flex: 1;
            padding-inline: 0px;
        }
    }
}

@media only screen and (max-width: 600px) {
    .blogContainer {
        .card img {
            width: 20rem;
        }
    }
}

@media only screen and (max-width: 570px) {
    .blogContainer {
        .card-content .card-description {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }
}

@media only screen and (max-width: 530px) {
    .blogContainer {
        .grid {
            padding-top: 1.6rem;
            gap: 1.8rem;
        }

        .header-text {
            font-size: 3rem;
        }
        .main-header {
            height: 24rem;
            margin-bottom: 2rem;
        }
        .secondary-text {
            font-weight: 700;
            font-size: 2.4rem;
        }

        .card img {
            width: 15rem;
        }
    }
}

@media only screen and (max-width: 500px) {
    .blogContainer {
        .card {
            gap: 1rem;
            border-bottom: 1px solid #bbb;
            padding: 0;
            background-color: transparent;
            padding-bottom: 1rem;
        }
        .card-heading {
            font-size: 2rem;
        }
        .card-description {
            font-size: 1.2rem;
        }
    }
}

@media only screen and (max-width: 400px) {
    .blogContainer {
        .grid {
            gap: 0.5rem;
        }
        .card {
            padding-bottom: 0.5rem;
        }
        .card img {
            width: 35%;
            border-radius: 2px;
        }
        .card-heading {
            font-size: 1.6rem;
        }
        .card-description {
            font-size: 1.2rem;
        }
        .main-header {
            height: 18rem;
        }
        .header-text {
            font-size: 2.6rem;
        }
        .secondary-text {
            font-weight: 600;
            font-size: 2.2rem;
        }
    }
}
