.profile {
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 7rem;
    main {
        display: flex;
        gap: 2.6rem;
        align-items: stretch; // This ensures all items start from the same line
        padding: 0 3rem;
        .profile {
            &__sidebar {
                width: 250px;
                height: 100vh;
                background-color: #fff;
                box-shadow: 0 0 10px rgba(0,0,0,0.1);
                padding: 20px;
                border-radius: 8px;
                flex-grow: 0;
                flex-shrink: 0;
                ul {
                    margin-top: 0;
                    padding: 0;
                    list-style: none;

                    li {
                        display: flex;
                        align-items: center;
                        padding: 10px 15px;
                        margin: 10px 0;
                        border-radius: 4px;
                        cursor: pointer;
                        transition: background-color 0.3s, color 0.3s;
                        &:hover {
                            background-color: #f0f0f0; // Hover effect
                        }

                        &.active {
                            background-color: #e0e0e0; // Active state
                            color: #333;
                        }

                        svg {
                            margin-right: 10px; // Space between icon and text
                        }
                        color: #333; 
                    }
                }
            }
        

            &__information {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.8rem;
                margin-bottom: 20px;
            }
            &__name {
                text-align: center;
                color: #333;
            }
            &__email {
                text-align: center;
                color: #333;
            }
            &__content {
                flex-grow: 1; /* Allows the content area to take up the rest of the space */
                display: flex;
                flex-direction: column;
                background-color: #fff;
                padding: 20px;
                border-radius: 8px;
                align-self: stretch;
            &__table-container { // Assuming your table is wrapped in a container
                    margin-top: 0; // Adjust or remove margin as necessary
                    padding-top: 0; // Standardize padding with the sidebar
                }
            .table {
                margin-top: 20px;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); // Adds shadow to match other inputs and boxes
                border-radius: 8px; // Rounded corners to match other forms and sections
                overflow: hidden; // Ensures the rounded corners clip the child elements

                .table-header, .table-row {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 16px 24px; // Increased padding for better spacing
                    border-bottom: 2px solid #eef2f5; // Lighter border to match other views
                }

                .table-header {
                    background-color: #f9f9f9; // Matches form heading backgrounds
                    color: #333;
                    font-weight: 600; // Ensuring bold text for headers
                }

                .table-row {
                    background-color: #fff; // Ensures white background for rows to match input fields
                    color: #666;

                    &:hover {
                        background-color: #f4f4f4; // Adds a hover effect similar to other clickable elements
                    }
                }

                div {
                    padding: 0 8px; // Uniform padding for content alignment
                    text-align: left;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .status-active, .status-inactive {
                    font-weight: 500; // Consistent font weight for status
                }

                .action-button {
                    background-color: #eef2f5;
                    border: 1px solid #ccc;
                    &:hover {
                        background-color: #ddd;
                    }
                }
            }
                form {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    width: 50rem;
                    label {
                        display: flex;
                        flex-direction: column;
                        color: #000;
                        font-size: 1.5rem;
                        gap: 0.6rem;
                        input {
                            padding: 8px 16px;
                            outline: none;
                            font-weight: 500;
                            font-size: 1.7rem;
                        }
                    }
                    p {
                        font-size: 1.4rem;
                        font-weight: 500;
                        color: red;
                    }
                    button {
                        background-color: black;
                        color: white;
                        height: 5rem;
                        padding: 0 3rem;
                        width: fit-content;
                        margin-left: auto;
                        font-size: 1.9rem;
                        font-weight: 600;
                        margin-top: 1.2rem;
                        cursor: pointer;
                        transition: all 0.3s ease-in;
                        border: 2px solid black;
                        &:hover {
                            background-color: white;
                            color: black;
                        }
                    }
                }
            }
            &__activity {
                display: flex;
                align-items: center;
                justify-content: space-between;
                div {
                    font-size: 2rem;
                    font-weight: 600;
                    color: black;
                }
                span {
                    display: flex;
                    align-items: center;
                    gap: 1.2rem;
                    font-size: 1.7rem;
                    font-weight: 500;
                    color: black;
                }
            }
            &__dashboard {
                margin-top: 8.8rem;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 2rem;
                span {
                    width: 28rem;
                    height: 8rem;
                    font-size: 1.8rem;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: 0px 4px 4px 0px #00000040;
                    color: black;
                    background-color: white;
                    border-radius: 4px;
                    transition: all 0.2s ease-in;
                    cursor: pointer;
                    &:hover {
                        filter: brightness(93%);
                    }
                }
            }
        }
    }

    &__row {
        margin-top: 8.9rem;
    }

    &__section {
        gap: 3.6rem;
        display: flex;
        align-items: center;
        &.form {
            flex-direction: column;
            align-items: start;
            gap: 2.2rem;
        }
    }
    &__header {
        font-size: 2.8rem;
        font-weight: 600;
        color: #000;
    }
    &__container {
        padding: 4rem;
        width: 100%;
        background: white;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        border-radius: 1rem;
        gap: 5.4rem;
        display: flex;
        flex-direction: column;

        &-picture {
            background-image: url("/assets//img/avatar.jpg");
            width: 7rem;
            height: 7rem;
            background-size: cover;
            background-position: center;
            border-radius: 50%;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        }
        &-box {
            display: flex;
            flex-direction: column;
            gap: 0.8rem;
        }
        &-name {
            font-size: 2.4rem;
            font-weight: 500;
            color: #000;
            text-transform: capitalize;
        }
        &-email {
            font-size: 1.5rem;
            font-weight: 600;
            color: #7c7c7c;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .profile {
        main {
            padding: 0 3rem;
        }
    }
}

@media only screen and (max-width: 1100px) {
    .profile {
        main {
            flex-direction: column;
            align-items: center;

            .profile {
                &__activity {
                    display: none;
                }
                &__content {
                    flex-direction: column;
                }
                &__sidebar {
                    ul {
                        flex-direction: row;
                        justify-content: space-around;
                        flex-wrap: wrap;
                    }
                }
                &__dashboard {
                    margin-top: 0;
                    justify-content: center;
                }
            }
        }
        &__section.form {
            margin-top: 0 !important;
            align-items: center;
        }
        &__row {
            margin-top: 0;
        }
    }
}

@media only screen and (max-width: 600px) {
    .profile {
        &__container {
            form {
                width: 100%;
            }
        }
    }
    .profile main .profile__content form {
        width: 100%;
    }
}


.table-header, .table-row {
    display: flex;
    justify-content: space-between;
    align-items: center; // Ensures vertical alignment is centered
    padding: 12px 20px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #e1e1e1;
  }
  
  .table-header {
    background-color: #eef2f5;
    color: #333;
    font-weight: 500;
  }
  
  .table-row {
    color: #666;
  }
  
  // Specific column styling
  .table-header div, .table-row div {
    flex: 1; // Assign flex grow factor to each div to ensure they take up equal space
    text-align: left; // Align text to the left
    overflow: hidden; // Hide overflowed content
    text-overflow: ellipsis; // Show ellipsis when content overflows
    white-space: nowrap; // Prevent text from wrapping to the next line
  }
  
  // You can assign more width to the first column if needed
  .table-header div:nth-child(1), .table-row div:nth-child(1) {
    flex: 2; // Allow the first column to take more space
  }
  
  .status-active {
    color: #4CAF50;
  }
  
  .status-inactive {
    color: #F44336;
  }
  
  .action-button {
    padding: 10px 20px; // adjust as needed
    border-radius: 5px; // adjust as needed
    font-size: 16px; // adjust as needed
    margin-right: 10px; // adjust as needed
  }
  
  .edit-button {
    background-color: #4CAF50; // green
    color: white;
  }
  
  .delete-button {
    background-color: #f44336; // red
    color: white;
  }
  .action-button:hover {
    background-color: #ddd;
  }
  

  .alerts-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .alerts-count {
    align-self: flex-end;
    display: flex;
    align-items: center;
    color: black; // or any other contrasting color
  }

  .profile__content {
    display: flex;
    flex-direction: row;
    align-items: stretch; // Ensures both children stretch to take available vertical space
    gap: 3.6rem;
    padding: 0 6rem;
    }
    .profile__right-sidebar {
    width: 200px; // Adjust based on your design
    border-left: 1px solid #ccc; // Just for visual separation
  }

