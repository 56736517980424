.header {
  width: 100vw;
  background-color: rgb(40, 40, 40);
  padding: 1.0rem 3rem;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 8000;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .headerLogo {
    font-size: 2rem;
    color: white;
    text-decoration: none;
    cursor: pointer;
    img {
      width: 200px;
    }
  }

  .headerNav {
    display: flex;
    align-items: center;

    .headerLink {
      font-size: 1.4rem;
      color: #ddd;
      transition: all 0.2s ease-out;
      margin-left: 2.4rem;

      &:hover {
        color: #eee;
      }
      &.active {
        color: white;
      }
    }

    .headerLogin {
      background: none;
      cursor: pointer;
      padding: 5px 10px;
      border: 1px solid #ddd;
      color: #ddd;
      font-family: "Montserrat", sans-serif;
      transition: all 0.2s ease-out;
      margin-left: 2.4rem;

      &:hover {
        border: 1px solid #eee;
        color: #eee;
        border-radius: 5px;
      }

      &.logout {
        background-color: rgb(255, 71, 71);
        color: white;
        border: none;
      }
    }
  }
}

.modal {
  padding: 2.4rem 3.6rem !important;
  &__title {
    font-size: 3.2rem;
    font-weight: 600;
  }
  form {
    display: flex;
    margin-top: 2.4rem;
    flex-direction: column;
    gap: 1.6rem;
    input {
      width: 450px;
      padding: 8px 16px;
      outline: none;
    }
    button {
      margin-left: auto;
      background: none;
      cursor: pointer;
      padding: 5px 10px;
      border: 1px solid #ddd;
      color: #ddd;
      font-family: "Montserrat", sans-serif;
      transition: all 0.2s ease-out;
      font-size: 1.8rem;
      &:hover {
        border: 1px solid #eee;
        color: #eee;
        border-radius: 5px;
      }
    }
  }
  p {
    font-size: 1.5rem;
    font-weight: 400;
    cursor: pointer;
    text-decoration: underline;
  }
  .error {
    font-size: 1.3rem;
    font-weight: 400;
    color: red;
    width: 450px;
  }
}

@media only screen and (max-width: 600px) {
  .modal {
    width: calc(100% - 72px);
    form {
      input {
        width: 100%;
      }
    }
    .error {
      width: 100%;
    }
  }
}

.MuiAlert-message {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  text-transform: capitalize;
}

@media only screen and (max-width: 450px) {
  .headerLogo {
    img {
      max-width: 90px;
      height: auto;
    }
  }
}
