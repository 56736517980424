@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
}

html {
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    background-color: #eee;
    color: white;
}

.app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

h1 {
    font-size: 5rem;
    font-weight: 700;
}

button {
    outline: none;
    font-family: "Montserrat", sans-serif;
    border: none;
    cursor: pointer;
}

.breadCrumbs {
    display: flex;
    align-items: center;
    padding: 3rem;
    gap: 8px;
    div {
        display: flex;
        align-items: center;
        gap: 12px;
        color: #000000;
        a {
            color: inherit;
            text-decoration: none;
            font-size: 1.6rem;
            border-bottom: 1px solid transparent;
            transition: all 0.2s ease-in-out;
            &:hover {
                border-color: #000;
            }
        }

        span {
            font-size: 2.4rem;
            &.reverse {
                transform: rotate(-180deg);
                line-height: 1.2;
            }
        }
    }
}

.grain-container {
    display: flex;
    align-items: center;
    gap: 16px;
    input {
        width: 100px;
    }
}

.lowest-price {
    display: flex;
    align-items: center;
    gap: 6px;
    font-weight: 500 !important;
    color: rgb(27, 27, 147) !important;
    svg {
        width: 12px;
        height: 12px;
        path {
            stroke: rgb(27, 27, 147);
        }
    }
}

@media only screen and (max-width: 1300px) {
    .grain-container {
        flex-direction: column;
        input {
            width: 100%;
        }
    }
}
@media only screen and (max-width: 1000px) {
    .grain-container {
        // input {
        //     width: auto;
        // }
    }
}

.MuiModal-root {
    z-index: 7000 !important;
}

.MuiSnackbar-root {
    z-index: 9999 !important;
}

.table__options {
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;

    .pagination {
        margin-inline: auto;
        padding-left: 10%;
    }
    label {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-right: 4rem;
    }
}

.MuiSlider-thumb {
    background-color: #000 !important;
}

.MuiSlider-root {
    color: #000 !important;
}

.pagination {
    --bs-pagination-padding-x: 0.75rem;
    --bs-pagination-padding-y: 0.375rem;
    --bs-pagination-font-size: 1rem;
    --bs-pagination-color: var(--bs-link-color);
    --bs-pagination-bg: #fff;
    --bs-pagination-border-width: 1px;
    --bs-pagination-border-color: #dee2e6;
    --bs-pagination-border-radius: 0.375rem;
    --bs-pagination-hover-color: var(--bs-link-hover-color);
    --bs-pagination-hover-bg: #e9ecef;
    --bs-pagination-hover-border-color: #dee2e6;
    --bs-pagination-focus-color: var(--bs-link-hover-color);
    --bs-pagination-focus-bg: #e9ecef;
    --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(1, 4, 8, 0.25);
    --bs-pagination-active-color: #fff;
    --bs-pagination-active-bg: #000000;
    --bs-pagination-active-border-color: #000000;
    --bs-pagination-disabled-color: #6c757d;
    --bs-pagination-disabled-bg: #fff;
    --bs-pagination-disabled-border-color: #dee2e6;
    display: flex;
    padding-left: 0;
    list-style: none;
}
.page-link {
    position: relative;
    display: block;
    padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
    font-size: var(--bs-pagination-font-size);
    color: var(--bs-pagination-color);
    text-decoration: none;
    background-color: var(--bs-pagination-bg);
    border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
    transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .page-link {
        transition: none;
    }
}
.page-link:hover {
    z-index: 2;
    color: var(--bs-pagination-hover-color);
    background-color: var(--bs-pagination-hover-bg);
    border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
    z-index: 3;
    color: var(--bs-pagination-focus-color);
    background-color: var(--bs-pagination-focus-bg);
    outline: 0;
    box-shadow: var(--bs-pagination-focus-box-shadow);
}
.active > .page-link,
.page-link.active {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: var(--bs-pagination-active-bg);
    border-color: var(--bs-pagination-active-border-color);
}
.disabled > .page-link,
.page-link.disabled {
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color);
}
.page-item:not(:first-child) .page-link {
    margin-left: -1px;
}
.page-item:first-child .page-link {
    border-top-left-radius: var(--bs-pagination-border-radius);
    border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
    border-top-right-radius: var(--bs-pagination-border-radius);
    border-bottom-right-radius: var(--bs-pagination-border-radius);
}
.pagination-lg {
    --bs-pagination-padding-x: 1.5rem;
    --bs-pagination-padding-y: 0.75rem;
    --bs-pagination-font-size: 1.25rem;
    --bs-pagination-border-radius: 0.5rem;
}
.pagination-sm {
    --bs-pagination-padding-x: 0.5rem;
    --bs-pagination-padding-y: 0.25rem;
    --bs-pagination-font-size: 0.875rem;
    --bs-pagination-border-radius: 0.25rem;
}

// .ant-select {
//     max-width: 640px;
//     align-self: center;
//     align-items: center;
//     display: flex;
//     width: 100%;
//     background-color: rgb(40, 40, 40) !important;
//     height: 52px;
//     color: white !important;
//     border-radius: 8px !important;
//     .ant-select-selector {
//         border-color: rgb(40, 40, 40) !important;
//         font-size: 1.8rem;
//         background-color: rgb(40, 40, 40) !important;
//         color: white !important;
//         box-shadow: none !important;
//         input {
//             color: white !important;
//             font-size: 1.8rem !important;
//         }
//         &:focus-visible {
//             outline: none;
//         }
//         .ant-select-selection-item {
//             font-size: 1.8rem !important;
//             color: rgba(255, 255, 255, 0.757);
//         }
//     }
//     .ant-select-selection-placeholder {
//         font-size: 1.8rem !important;
//         color: white !important;
//     }
//     .ant-select-arrow {
//         color: rgba(255, 255, 255, 0.8);
//     }
// }

.not-found {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 18px;
    font-weight: 500;
}

.notistack-SnackbarContainer {
    z-index: 9999 !important;
    font-family: "Montserrat", sans-serif !important;
    #notistack-snackbar {
        font-size: 12px !important;
    }
}

@media only screen and (max-width: 800px) {
    html {
        font-size: 56.25%;
    }
}
@media only screen and (max-width: 650px) {
    html {
        font-size: 50%;
    }
}

@media only screen and (max-width: 450px) {
    html {
        font-size: 43.75%;
    }
}

@media only screen and (min-width: 2000px) {
    html {
        font-size: 68.5%;
    }
}
@media only screen and (min-width: 2400px) {
    html {
        font-size: 75%;
    }
}

.lds-ripple {
    display: inline-block;
    position: relative;
    align-self: center;
    width: 80px;
    margin-top: 120px;
    margin-bottom: auto;
    height: 80px;
}
.lds-ripple div {
    position: absolute;
    border: 4px solid #000000;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}
@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

.grecaptcha-badge {
    visibility: hidden;
  }