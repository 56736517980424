.blog {
  color: #27333c;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  min-height: calc(100vh - 100px);

  p {
    line-height: 1.6 !important;
  }

  .main {
    width: 100%;
    max-width: 1000px; /* Increased max-width for wider content */
    margin: 0 auto;
    padding: 40px 30px; /* Added padding for better spacing */
    background: #ffffff; /* Adding a background color */
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); /* Adding subtle shadow */
    border-radius: 12px; /* Adding rounded corners */

    &-header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 24px;

      a {
        margin-bottom: 16px;
        color: #27333c;
        opacity: 0.8;
        &:hover {
          opacity: 1;
        }
      }

      .meta-info {
        font-size: 14px;
        color: #888;
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        width: 100%;

        .author,
        .timestamp {
          display: inline-block;
        }
      }
    }
  }

  .header-text {
    font-size: 2.8rem; /* Adjusted font size for better readability */
    width: 100%;
    margin-bottom: 24px;
    font-weight: 700;
    letter-spacing: 0.1rem;
    text-align: center;
    color: #1d1d1d; /* Darker color for better contrast */
  }

  .image {
    width: 100%;
    border-radius: 10px; /* Adding rounded corners to images */
  }

  .image-section {
    margin-bottom: 1.2rem;
    text-align: center; /* Centering the image section */
  }

  .text-description {
    font-size: 1.8rem; /* Adjusted font size for better readability */
    line-height: 1.8;
    color: #333; /* Darker color for better contrast */
    padding: 0 20px; /* Adding padding for text */
  }

  .lds-ripple {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
}

@media only screen and (max-width: 750px) {
  .blog {
    margin-block: 40px 20px;

    .header-text {
      margin-bottom: 20px;
      font-size: 2.5rem; /* Adjusted font size for smaller screens */
    }

    .text-description {
      font-size: 1.6rem;
      line-height: 1.6;
    }

    .main {
      width: 100%;
      padding: 3.2rem;
      padding-top: 50px;
    }
  }
}

@media only screen and (max-width: 665px) {
  .blog {
    .header-text {
      margin-bottom: 4rem;
      font-size: 2.2rem; /* Adjusted font size for smaller screens */
    }

    .text-description {
      font-size: 1.6rem;
      line-height: 1.6;
    }
  }
}

@media only screen and (max-width: 600px) {
  .blog {
    margin-block: 64px 24px;

    .header-text {
      margin-bottom: 4rem;
      font-size: 2rem; /* Adjusted font size for smaller screens */
    }

    .text-description {
      font-size: 1.4rem;
      line-height: 1.5;
    }
  }
}

@media only screen and (max-width: 540px) {
  .blog {
    .header-text {
      margin-bottom: 3.4rem;
      font-size: 1.8rem; /* Adjusted font size for smaller screens */
    }

    .text-description {
      font-size: 1.2rem;
      line-height: 1.5;
    }
  }
}

@media only screen and (max-width: 470px) {
  .blog {
    .header-text {
      margin-bottom: 2.4rem;
      font-size: 1.6rem; /* Adjusted font size for smaller screens */
    }

    .text-description {
      font-size: 1.2rem;
      line-height: 1.4;
    }
  }
}

@media only screen and (max-width: 410px) {
  .blog {
    .main {
      width: auto;
    }

    .header-text {
      margin-bottom: 2.2rem;
      font-size: 1.4rem; /* Adjusted font size for smaller screens */
    }

    .text-description {
      font-size: 1.2rem;
      line-height: 1.3;
    }
  }
}

@media only screen and (max-width: 370px) {
  .blog {
    .header-text {
      margin-bottom: 2rem;
      font-size: 1.2rem; /* Adjusted font size for smaller screens */
    }

    .text-description {
      font-size: 1rem;
      line-height: 1.2;
    }
  }
}

@media only screen and (max-width: 330px) {
  .blog {
    .header-text {
      margin-bottom: 1.6rem;
      font-size: 1rem; /* Adjusted font size for smaller screens */
    }

    .text-description {
      font-size: 0.9rem;
      line-height: 1.2;
    }
  }
}